// @flow
import iFrameResizer from "iframe-resizer/js/iframeResizer";

// Wrap functions in scope so they don't conflict with website globals
function ticketbutlerEmbedder() {
  function createIframeElement(url) {
    let iframe = document.createElement("iframe");
    if (url) iframe.setAttribute("src", url);
    // IMPORTANT: Allow scrolling so users can access payment information on
    // QuickPay (where we do not know the window height)
    iframe.setAttribute("scrolling", "yes");
    iframe.setAttribute(
      "style",
      // Mostly hacks for iOS. Test iOS if changing this
      "display: block; width: 0px; min-width: 100%; border: 0; overflow: scroll; min-height: 355px"
    );
    return iframe;
  }

  function deriveCookieConsent() {
    let cookieList = document.cookie.split(";");
    let cookieKeys = cookieList.map(cookie => cookie.split("=")[0]);

    let consent = [];
    if (
      cookieKeys.some(
        item =>
          item.includes("ga") || item.includes("gid") || item.includes("gtag")
      )
    ) {
      consent.push("targeting");
    }
    if (
      cookieKeys.some(
        item =>
          item.includes("gads") ||
          item.includes("fbp") ||
          item.includes("IDE") ||
          item.includes("NID")
      )
    ) {
      consent.push("performance");
    }
    return consent;
  }

  // To enable cross-domain tracking, we must retreive the "_gl" (Google Linker)
  // parameter for the iframe URL
  function getGoogleLinkerParameter(domain) {
    //create form element, give it an action, make it hidden and prevent the submit event
    const formNode = document.createElement("FORM");
    formNode.action = "https://" + domain;
    formNode.style.opacity = "0";
    formNode.addEventListener("submit", function(event) {
      event.preventDefault();
    });

    //create a button node, make it type=submit and append it to the form
    const buttonNode = document.createElement("BUTTON");
    buttonNode.type = "submit";
    formNode.append(buttonNode);

    //append the form (and button) to the DOM
    document.body.append(formNode);

    //trigger a click on the button node to submit the form
    buttonNode.click();

    //check for the input[name=_gl] hidden input in the form (if decoration worked)
    const _glNode = formNode.querySelector('input[name="_gl"]');

    if (_glNode) {
      return _glNode.value;
    }

    return null;
  }

  function embedIframes(extraParams) {
    // Use the users' language to determine the initial set language
    const browserLanguage =
      (window.navigator.userLanguage || window.navigator.language).indexOf(
        "da"
      ) !== -1
        ? "da"
        : "en";

    function makeIframe(url, noBranding, domain, lang) {
      const { logo, name, homepageUrl } = getWlForUrl(url);

      const termsAndCondUrl = `https://${domain}/${lang}/tandc`;
      const privacyPolicyUrl = `https://${domain}/${lang}/privacy-policy`;

      let container = document.createElement("div");
      container.setAttribute("style", "width: 100%; margin: 0 auto;");

      const iframe = createIframeElement(url);
      container.appendChild(iframe);

      // Do not add footer if no branding is set
      if (!noBranding) {
        let footer = document.createElement("div");

        footer.setAttribute(
          "style",
          "display: flex; align-items: center; justify-content: space-between; flex-direction: column;"
        );

        let footerLinksContainer = document.createElement("div");
        footerLinksContainer.setAttribute(
          "style",
          "margin: auto 0; text-align: left; font-size: 12px; margin-bottom: 10px;"
        );

        let termsAndConditions = document.createElement("a");
        termsAndConditions.innerText =
          lang === "da" ? "Vilkår Og Betingelser" : "Terms and conditions";
        termsAndConditions.setAttribute("href", termsAndCondUrl);
        termsAndConditions.setAttribute("target", "_blank");
        termsAndConditions.setAttribute("rel", "noopener noreferrer");
        termsAndConditions.setAttribute(
          "style",
          `text-decoration: none; margin-right: 80px; color: #333333;`
        );

        let privacyPolicy = document.createElement("a");
        privacyPolicy.innerText =
          lang === "da" ? "Privatpolitik" : "Privacy policy";
        privacyPolicy.setAttribute("href", privacyPolicyUrl);
        privacyPolicy.setAttribute("target", "_blank");
        privacyPolicy.setAttribute("rel", "noopener noreferrer");
        privacyPolicy.setAttribute(
          "style",
          "text-decoration: none; color: #333333;"
        );

        let footerDevContainer = document.createElement("div");
        footerDevContainer.setAttribute(
          "style",
          "display: flex; align-items: center;"
        );

        let footerText = document.createElement("span");
        footerText.innerText = "Powered by";
        footerText.setAttribute(
          "style",
          "font-weight: 300; margin: 10px 0; font-size: 15px; color: #63696d; font-family: Helvetica, sans-serif; padding-left: 5px;"
        );

        let footerLink = document.createElement("a");
        footerLink.setAttribute("href", homepageUrl);
        footerLink.setAttribute("target", "_blank");
        footerLink.setAttribute("rel", "noopener noreferrer");

        let footerLogo = document.createElement("img");
        footerLogo.setAttribute("src", logo);
        footerLogo.setAttribute("alt", name);
        footerLogo.setAttribute(
          "style",
          "margin: 5px; width: 100%; max-width: 200px; height: auto;"
        );

        footerLinksContainer.appendChild(termsAndConditions);
        footerLinksContainer.appendChild(privacyPolicy);

        footer.appendChild(footerLinksContainer);

        footerDevContainer.appendChild(footerText);

        footerLink.appendChild(footerLogo);
        footerDevContainer.appendChild(footerLink);

        footer.appendChild(footerDevContainer);

        container.appendChild(footer);
      }

      return container;
    }

    function getWlForUrl(url) {
      const isBilletfix = url.indexOf("billetfix.dk") !== -1;
      if (isBilletfix) {
        return {
          name: "Billetfix",
          homepageUrl: "https://billetfix.dk",
          logo:
            "https://cdn.ticketbutler.io/event-embedder/billetfix_by_ticketbutler-logo.png"
        };
      } else {
        // Use Ticketbutler
        return {
          name: "Ticketbutler",
          homepageUrl: "https://ticketbutler.io",
          logo:
            "https://cdn.ticketbutler.io/event-embedder/ticketbutler-logo.png"
        };
      }
    }

    const IFRAME_TYPES = {
      EVENT: "EVENT",
      EVENT_LIST: "EVENT_LIST"
    };

    // Get and insert iframes not loaded yet (in case user has pasted the script twice)
    let iframeContainers = document.querySelectorAll(
      ".ticketbutler-iframe:not([loaded])"
    );

    if (window.google_tag_data && iframeContainers[0]) {
      let googleLinkerId = getGoogleLinkerParameter(
        iframeContainers[0].dataset.domain
      );
      if (googleLinkerId) {
        extraParams = extraParams + "&_gl=" + googleLinkerId;
      }
    }

    for (let i = 0; i < iframeContainers.length; ++i) {
      let el = iframeContainers[i];
      // Set this iframe container as "loaded" so it is not inserted twice
      el.setAttribute("loaded", true);
      if (el instanceof HTMLDivElement) {
        // We moved to data-slug and data-domain so that the event URL can be
        // changed and reflected in this package easily.
        let type;
        let domain;
        let slug;
        let lang;
        let params;
        let noBranding;
        if (el.dataset.type) {
          type = el.dataset.type;
          domain = el.dataset.domain;
          slug = el.dataset.slug;
          lang = el.dataset.lang || browserLanguage;
          params = el.dataset.params;
          noBranding = el.dataset.noBranding == "" ? true : false;
          // Add iframe param for checkout app
          if (params) {
            params = params + "&iframe=true";
          } else params = "?iframe=true";
        } else {
          // Support for old iframes with data-url attribute.
          const parts = el.dataset.url.split("/");
          const nonEmptyParts = parts.filter(p => p.length > 0);
          type =
            nonEmptyParts[3] === "events"
              ? IFRAME_TYPES.EVENT_LIST
              : IFRAME_TYPES.EVENT;
          domain = nonEmptyParts[1];
          lang = nonEmptyParts[2];
          // Event slug or event list team slug
          // Handle params
          if (type === IFRAME_TYPES.EVENT) {
            slug = nonEmptyParts[4].split("?")[0];
          }
          if (type === IFRAME_TYPES.EVENT_LIST) {
            if (nonEmptyParts.length >= 8) {
              // A slug is optional for event list, so check if it's there
              slug = nonEmptyParts[4].split("?")[0];
            }
          }

          params = el.dataset.url.split("?")[1];
          if (params) {
            params = "?" + params; // Add the ? back on
          } else params = "?iframe";
        }

        params = params + extraParams;

        // Put it all together
        const prefix = type === IFRAME_TYPES.EVENT_LIST ? "events" : "e";
        const url =
          "https://" +
          [domain, lang, prefix, slug].filter(Boolean).join("/") +
          "/" +
          params;

        el.appendChild(makeIframe(url, noBranding, domain, lang));
        // Call the resize when loaded
        iFrameResizer(
          {
            heightCalculationMethod: "lowestElement",
            // IMPORTANT: Allow scrolling so users can access payment information on
            // QuickPay (where we do not know the window height)
            scrolling: true
          },
          ".ticketbutler-iframe iframe"
        );
      }
    }

    // Init modal iframe in page
    const modalIframe = createIframeElement(null);
    modalIframe.classList.add("ticketbutler-modal");
    document.body.append(modalIframe);

    // Inject open modal CSS
    let cssBlock = document.createElement("style");
    cssBlock.innerHTML = `
html.ticketbuter-overlay-active,
html.ticketbuter-overlay-active body {
  overflow: hidden;
}
.ticketbutler-modal:not(.open) {
  display: none !important;
}
.ticketbutler-modal.open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vww;
  height: 100vh;
  z-index: 99999;
}
.ticketbutler-modal.external-payment-active {
  background-color: white;
}
`;
    document.head.appendChild(cssBlock);

    // Handling of messages sent from within iframes
    window.addEventListener("message", function(e) {
      // When the checkout dialog is opened, fix the iframe on top of the browser
      // window
      if (e.data && e.data.type && e.data.type.includes("TICKETBUTLER")) {
        if (e.data.type === "TICKETBUTLER_CHECKOUT_MODAL") {
          if (e.data.open) {
            if (modalIframe.classList.contains("open")) {
              // Modal is already open, do nothing
            } else {
              document.documentElement.classList.add(
                "ticketbuter-overlay-active"
              );
              modalIframe.setAttribute(
                "src",
                e.data.url +
                  (e.data.url.includes("?") ? "&" : "?") +
                  "isCheckoutModal" +
                  extraParams
              );
              modalIframe.classList.add("open");
            }
          } else {
            document.documentElement.classList.remove(
              "ticketbuter-overlay-active"
            );
            modalIframe.removeAttribute("src");
            modalIframe.classList.remove("open");
          }
        }
        // Listen for modal navigating to payment message
        // We make the background white so that it doesn't "flicker" when loading
        if (e.data.type === "TICKETBUTLER_EXTERNAL_PAYMENT") {
          if (e.data.active) {
            modalIframe.classList.add("external-payment-active");
          } else {
            modalIframe.classList.remove("external-payment-active");
          }
        }
      }
    });
  }

  function main() {
    if (!window.ticketbutlerEventEmbedderLoaded) {
      window.ticketbutlerEventEmbedderLoaded = true;
      let extraParams = "";

      const cookieConsent = deriveCookieConsent();
      for (let category of cookieConsent) {
        extraParams = extraParams + "&cookieconsent=" + category;
      }

      embedIframes(extraParams);
    } else {
      console.warn(loadedWarnMessage);
    }
  }

  // Check the document readyState to indentify if all DOM content has been
  // loaded. If not, set a listener to fire when ready
  // Set a variable on the window when an embedder has been queued or called in
  // case multiple scripts have been pasted on the page

  const loadedWarnMessage =
    'The Ticketbutler event embedding script has been requested more\
  than once. Please only include the "<script>" tag only once on the page.';

  switch (document.readyState) {
    case "complete":
      main();
      break;
    default:
      document.addEventListener("readystatechange", function() {
        if (document.readyState === "complete") {
          main();
        }
      });
  }

  // Handling EU Cookie Banners
  // Listen to changes to the cookies. If "marketing" cookies are present,
  // enable marketing cookies in the iframe
  if (window.cookieStore) {
    window.cookieStore.addEventListener("change", function() {
      const cookieConsent = deriveCookieConsent();

      if (cookieConsent.length) {
        const iframes = document.querySelectorAll(
          ".ticketbutler-iframe iframe"
        );
        for (let iframe of iframes) {
          const url = new URL(iframe.src);
          const params = new URLSearchParams(url.search);
          if (
            params
              .getAll("cookieconsent")
              .sort()
              .toString() === cookieConsent.sort().toString()
          ) {
            // No need to update URLs
            return;
          }
          params.delete("cookieconsent");
          for (let category of cookieConsent) {
            params.append("cookieconsent", category);
          }
          console.log(
            "[Ticketbutler embed] Cookies updated - refreshing iframes with new consent settings"
          );
          iframe.src = iframe.src.split("?")[0] + "?" + params.toString();
        }
      }
    });
  }
}

ticketbutlerEmbedder();
